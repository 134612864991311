import React from 'react';
import { HeroText, CaseStudyPreviews, Layout, SEO, Title } from 'components';

export default ({ location }) => (
  <Layout location={location}>
    <SEO
      pageSpecificTitle="Portfolio"
      pageSpecificDescription="Check out a selection of some of our most recent work, spanning strategy, video, live stream,
      and training."
      pathname={location.pathname}
    />
    <div style={{ paddingTop: '160px' }}>
      <Title text="Our Work" pathname={location.pathname} />
      <HeroText style={{ maxWidth: '750px'}}>
        Check out a selection of our most recent work, spanning strategy, video, live stream, and training.
      </HeroText>
      <CaseStudyPreviews pathname={location.pathname} />
    </div>
  </Layout>
);
